<!-- 充值记录 -->
<template>
  <div>
    <!-- 内容 -->
    <div class="financebox">
      <el-card>
        <div class="global-search-box">
          <div class="search-content">
            <div class="search-item">
              <el-input placeholder="请输入内容" v-model="search.selectObj.value">
                <template slot="prepend">
                  <el-select v-model="search.selectObj.option" placeholder="请选择">
                    <el-option value="order_no" label="充值单号"></el-option>
                    <el-option value="user_info" label="用户信息"></el-option>
                  </el-select>
                </template>
              </el-input>
            </div>
            <div class="search-item">
              <label class="search-label">充值时间：</label>
              <div class="search-time-between">
                <el-date-picker v-model="search.created_at" type="datetimerange" :picker-options="pickerOptionLater"
                  start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </div>
            </div>
            <div class="search-item">
              <label class="search-label">会员等级：</label>
              <el-select v-model="search.level" clearable placeholder="请选择">
                <el-option v-for="(item, index) in common_info['user.level_list']" :key="item.id" :label="item.name"
                  :value="item.level">
                </el-option>
              </el-select>
            </div>
            <div class="search-item">
              <label class="search-label">充值方式：</label>
              <el-select v-model="search.pay_type" clearable placeholder="请选择">
                <el-option v-for="(item, index) in reOrderPayType" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="search-item">
              <label class="search-label">状态：</label>
              <el-select v-model="search.is_pay" clearable placeholder="请选择">
                <el-option v-for="(item, index) in reOrderIsPay" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="serach-buttom-button">
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button @click="exportTable">导出报表</el-button>
            <el-button @click="handleClear">重置条件</el-button>
          </div>

        </div>
      </el-card>
      <el-card>
        <div class="boxtableconter1">
          <div class="boxtable">
            <div class="financebut">
              <div class="batchtable">
                <div class="batchtablecon">
                  <el-table :data="tableData" style="width: 100%" @selection-change="singletable">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column label="用户信息" min-width="200"  align="left">
                      <template slot-scope="scope">
                        <div class="memberuser clearfloat">
                          <div class="memberuserleft">
                            <img :src="scope.row.users.avatar_url" alt="avatar" />
                          </div>
                          <div class="memberuseright">
                            <p>{{ scope.row.users.nickname }}</p>
                            <p>ID: {{ scope.row.users.id }}</p>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="手机号" prop="users.binding" width="220"></el-table-column>
                    <el-table-column label="充值方式" width="220">
                      <template slot-scope="scope">
                        <span>{{
                          common_info["re_order.pay_type"][scope.row["pay_type"]]
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="充值金额" prop="pay_price" width="220">
                    </el-table-column>
                    <el-table-column label="充值时间" width="220">
                      <template slot-scope="scope">
                        <div class="tableaddress">
                          <span>{{ scope.row.created_at }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="invoicesend" width="220">
                      <template slot-scope="scope">
                        <div class="tableaddress">
                          <span>{{
                            common_info["re_order.is_pay"][scope.row.is_pay]
                          }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="200" align="center">
                      <template slot-scope="scope">
                        <div class="setoperate">
                          <a href="javascript:void(0);" @click="handleOper(scope.row)">操作人信息</a>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="orderlastbut clearfloat">
                  <!-- <div class="orderlastbutleft clearfloat">
                        <p><span class="ordercheck"><el-checkbox v-model="memberchecked"
                                    @change="allchange">当前页全选</el-checkbox></span></p>
                        <a href="javascript:void(0);" class="active">导出报表</a>
                                                                            </div> -->
                  <div class="orderlastbutright">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="currentPage1" :page-sizes="[10, 20, 30, 50]" :page-size="10"
                      layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>

    </div>
    <!-- 操作人信息弹层 -->
    <el-dialog title="操作人信息" class="operatortc" :visible.sync="operatortype">
      <div class="operatorbox">
        <p>
          管理员：
          <span>{{
            handleOper.userInfo && handleOper.userInfo.admin_user
            ? handleOper.userInfo.admin_user.user_name
            : "---"
          }}</span>
        </p>
        <p>
          后台操作账号：<span>{{
            handleOper.userInfo && handleOper.userInfo.admin_user
            ? handleOper.userInfo.admin_user.passport || "---"
            : "---"
          }}</span>
        </p>
        <p>
          余额充值：<span>{{
            (handleOper.userInfo && handleOper.userInfo.pay_price) || "---"
          }}</span>
        </p>
        <p>
          备注：<span>{{
            (handleOper.userInfo &&
              handleOper.userInfo.user_account &&
              handleOper.userInfo.user_account.desc) ||
            "---"
          }}</span>
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="operatortype = false">取 消</el-button>
        <el-button @click="operatortype = false" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导出报表 -->
    <diy-export-more ref="diyExportMore" dialogExportKeys="recharge.record.field.list"
      dialogExportUrl="/finance/re_order_export" :dialogExportIsSearch="true"></diy-export-more>
  </div>
</template>

<script>
import { mapState } from "vuex";
import common from "../../../common/common";
import { Message } from "element-ui";

export default {
  data() {
    return {
      search: {
        //搜索项
        selectObj: {
          //选择下拉输入值搜索字段
          option: "order_no", //选择下来key值
          value: "", //输入的值
          type: "selectSearch",
        },
        order_no: "", // 搜索模式
        user_info: "", //搜索内容
        pay_time: [], //充值时间
        level: "", //会员等级
        pay_type: "", //充值方式
        status: "", //状态
        created_at: []
      },
      searchObj: {
        search: [],
        order: [
          {
            key: "created_at",
            value: "asc",
          },
        ],
      },
      pageInfo: {
        page: 1,
        limit: 10,
      },
      tableData: [],
      currentPage1: 1,
      total: 0, //总数
      valuefirst: "", //充值记录
      recharge: "", //充值方式
      rechargetype: "", //充值状态
      rechargeshop: "", //充值门店
      operatortype: false, //控制操作人信息弹层是否显示
      pickerOptionLater: {
        // 设定时间选择区间截止为当天->23.59.59
        disabledDate(time) {
          var date = new Date(new Date().setHours(23, 59, 59, 59)).getTime()
          return time.getTime() > date
        },
        // 所有的截止时间为当天的23.59.59
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const start = new Date().setHours(0, 0, 0, 0);
            const end = new Date().setHours(23, 59, 59, 59);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '7天',
          onClick(picker) {
            const end = new Date().setHours(23, 59, 59, 59);
            const start = new Date();
            start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '30天',
          onClick(picker) {
            const end = new Date().setHours(23, 59, 59, 59);
            const start = new Date();
            start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
            picker.$emit('pick', [start, end]);
          }
        }]

      }
    };
  },
  beforeCreate() {
    // 清空commonInfo缓存
    this.$store.commit("commonInfo", "");
  },

  created() {
    //请求全局配置资源(user.level_list,user.is_freeze)
    common.getCommonInfo(this, {
      keys: Array.of("user.level_list", "re_order.pay_type", "re_order.is_pay"),
    });
    this.getOrderList();
  },

  methods: {
    // 提取搜索数据
    extractSearch() {
      return { ...this.searchObj, ...this.pageInfo };
    },
    // 打开导出报表弹窗
    exportTable() {
      this.$refs.diyExportMore.exportMore = true;
    },
    // 获取列表
    getOrderList() {
      this.$get(this.$apis.financeRecordApi, {
        ...this.searchObj,
        ...this.pageInfo,
      }).then((res) => {
        // console.log(res);
        if (res.code !== 200) {
          return Message.error({
            message: res.message,
            offset: 200,
          });
        }
        // 赋值
        this.$set(this, "tableData", res.data.list);
        this.total = res.data.list.length;
      });
    },
    handleSizeChange(limit) {
      this.$set(this, "pageInfo", { ...this.pageInfo, limit });
      this.getOrderList();
    },
    handleCurrentChange(page) {
      this.$set(this, "pageInfo", { ...this.pageInfo, page });
      this.getOrderList();
    },
    // 搜索
    handleSearch() {
      this.$set(this, "searchObj", { ...this.searchObj, ...this.pageInfo });
      this.search.created_at = [this.common.timeStampTurnTime(this.search.created_at[0]), this.common.timeStampTurnTime(this.search.created_at[1])]
      this.$set(
        this.searchObj,
        "search",
        common.handleSearch(this.search, "equal")
      );
      // 利用异步写法，等循环结束后执行
      setTimeout(() => {
        this.getOrderList();
      }, 0);
    },
    // 清空
    handleClear() {
      this.$set(this, "search", common.handleClear(this.search));
      this.handleSearch();
    },
    handleSizeChange(limit) {
      this.$set(this, "pageInfo", { ...this.pageInfo, limit });
      this.getOrderList();
    },
    handleCurrentChange(page) {
      this.$set(this, "pageInfo", { ...this.pageInfo, page });
      this.getOrderList();
    },
    // 单个表格被选中
    singletable(val) {
      this.multipleSelection = val;
      if (val.length == this.tableData.length) {
        this.memberchecked = true;
      } else {
        this.memberchecked = false;
      }
    },
    // 操作人信息按钮
    handleOper(row) {
      this.operatortype = true;
      this.handleOper.userInfo = row;
    },
  },
  computed: {
    reOrderPayType() {
      return common.mapSource2List(this.common_info["re_order.pay_type"] || {});
    },
    //
    reOrderIsPay() {
      return common.mapSource2List(this.common_info["re_order.is_pay"] || {});
    },
    common_info: {
      get() {
        return this.$store.state.common_info;
      },
      set() { },
    },
  },
};
</script>

<style scoped lang="less">
@import url("css/finance.css");
/deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
	background: #535353;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
	background: transparent;
}
.batchtablecon table tr:hover td{background: transparent;}
.orderlastbut{margin-top: 20px;}
.orderlastbutright{float: right;}
.batchtable {
  padding: 0;
}

/deep/.el-table th.el-table__cell {
  background-color: #f9f9fc !important;
}

/deep/.el-table .el-table__cell {
  padding: 15px 10px;
}

.boxtable {
  /deep/.el-table .el-table__cell {
    border-bottom: 1px solid #ebeef5;
  }
}
</style>